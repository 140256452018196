<template>
    <section class="proveedores-ver-main container-fluid">
        <navbar-admin titulo="Alamacén > Plancha de acero al carbono de baja resistencia">
            <el-tooltip content="Editar proveedor" placement="bottom" effect="light" visible-arrow>
                <button  
                    class="btn btn-square32-5d ml-2"
                    :class="permitAction('proveedores.editar') ? 'cr-pointer' : 'cr-not-allowed'" 
                    @click.stop="functionPermitAction('proveedores.editar', editarProveedor)"
                >
                    <i class="icon-pencil-outline text-white" />
                </button>
            </el-tooltip>
            <el-tooltip content="Eliminar proveedor" placement="bottom-end" effect="light" visible-arrow>
                <button 
                    class="btn btn-square32-5d ml-2"
                    :class="permitAction('proveedores.eliminar') ? 'cr-pointer' : 'cr-not-allowed'" 
                    @click.stop="functionPermitAction('proveedores.eliminar', eliminarProveedor)"
                >
                    <i class="icon-trash-can-outline text-white" />
                </button>
            </el-tooltip>
        </navbar-admin>
        <div class="row my-4 mx-0">
            <div class="col-12 col-md-auto col-lg-12 col-xl-11">
                <div class="bg-f5 border br-8 pr-4 br-50-6">
                    <div class="row mx-0 contenedor-info d-middle">
                        <img :src="detallesProveedor.imagen | helper-storage" alt="" class="img-logo-op wh-126 rounded-circle position-relative">
                        <div class="col py-2">
                            <div class="row">
                                <div class="col-12 col-lg-12">
                                    <p class="f-18 f-700">{{ detallesProveedor.nombre }}</p>
                                    <p class="f-14 f-500 my-2">Materiales que maneja: {{materiales}}</p>
                                    <div class="row">
                                        <div class="col-auto">
                                            <div class="d-middle">
                                                <i class="icon-account" />
                                                <p class="font-italic f-14">{{detallesProveedor.user_responsable || '-'}}</p>
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <div class="d-middle">
                                                <el-tooltip content="Número de equipos" placement="bottom" effect="light" visible-arrow>
                                                    <i class="icon-clipboard-list" />
                                                </el-tooltip>
                                                <p class="font-italic f-14">{{detallesProveedor.cotizaciones}} cotizaciones</p>
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <div class="d-middle">
                                                <i class="icon-shopping" />
                                                <p class="font-italic f-14">{{detallesProveedor.ordenes_compra}} Ordenes de compra</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <tabs :tabs="tabsVerProveedores" />
        <router-view />
        <!-- partials -->
        <modal-provider ref="modalEditarProveedor" @getProvider="obtenerDetallesProveedor({id_proveedor})" /> 
        <modal-eliminar ref="abrirEliminarProveedor" title="Eliminar proveedor" mensaje="¿Está seguro que quiere eliminar este proveedor?" />
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import modalProvider from '../partials/modalProvider.vue'
export default {
    components: { modalProvider },
    data(){
        return{
            id_proveedor: this.$route.params.id_proveedor,
            tabsVerProveedores: [
                {
                    titulo:'Información',
                    ruta:"proveedores.ver.informacion",
                },
                {
                    titulo:'Materiales',
                    ruta:"proveedores.ver.materiales",
                },
                {
                    titulo:'Cotizaciones',
                    ruta:"proveedores.ver.cotizaciones",
                },
                {
                    titulo:'Ordenes de compra',
                    ruta:"proveedores.ver.ordenes",
                },
                {
                    titulo:'Funcionarios',
                    ruta:"proveedores.ver.funcionarios",
                },
            ],
            materiales:''
        }
    },
    computed: {
        ...mapGetters({
            detallesProveedor: 'proveedores/ver/ver/provider_details',
        }),
    },
    watch: {
        detallesProveedor:{
            handler(val){
                this.spliceBreadcumbs([
                    {
                        position: 1,
                        breadcumb: {
                            name: val.nombre,
                            route: 'proveedores.ver',
                            params: { id_proveedor: val.id },
                        }
                    }
                ])
            },
            deep: true
        },  
        "$route.name"(route){
            this.replaceBreadcumb({position: 2, breadcumb: route })
        }
    },
    methods:{
        ...mapActions({
            obtenerDetallesProveedor: 'proveedores/ver/ver/Action_get_provider_details',
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            replaceBreadcumb: 'navigation/breadcumbs/replaceBreadcumb',
            spliceBreadcumbs: 'navigation/breadcumbs/spliceBreadcumbs',
            getSelects: 'selects/selects/sync',
        }),
        editarProveedor(){
            const { proveedor } = this.$route.params
            this.$refs.modalEditarProveedor.edit(proveedor)
        },
        eliminarProveedor(){
            this.$refs.abrirEliminarProveedor.toggle()
        },
        listaMaterialesProveedor(){
            const mats = this.detallesProveedor.materiales_maneja.map(e => e.material)
            this.materiales = mats.toString()
        }
    },
    async mounted(){
        this.getBreadcumbs(['proveedores.listado'])
        this.getSelects(['Action_get_select_materials','Action_get_selects_responsible_users'])
        this.replaceBreadcumb({position: 1, breadcumb: this.$route.name })
        await this.obtenerDetallesProveedor({id_proveedor: this.id_proveedor})
        this.listaMaterialesProveedor()
    },


}
</script>

<style lang="scss" scoped>
.proveedores-ver-main{
    background: white;
    .br-50-6{
        border-radius: 50rem 6rem 6rem 50rem !important;
    }
    .contenedor-info{
        .img-logo-op{
            left: -3px;
            top: -1px;
        }
    }
}
</style>