<template>
    <modal 
    ref="modalProvider" 
    tamano="modal-lg" 
    :titulo="`${id_proveedor ? 'Editar' : 'Crear' } proveedor`" 
    :adicional="id_proveedor ? 'Guardar' : 'Crear'" 
    @adicional="handleSaveProvider"
    >
        <div class="ml-5">
            <required text="Campos obligatorios"/>  
        </div>
        <ValidationObserver ref="validator">
            <div class="row justify-content-center m-3 f-15">
                <div class="col-12 mb-4">
                        <p class="f-11 text-muted text-center">Imagen proveedor</p>
                        <clean-cropper 
                        class="cropper-proveedor border cr-pointer mx-auto br-5"
                        ref="cleanCropper"
                        v-model="image" 
                        :options="slimOptions" 
                        :image="imagen"
                        />   
                </div>
                <div class="col-6 my-2">
                    <ValidationProvider v-slot="{ errors }" name="nombre" rules="required">
                        <p class="input-label-top">Nombre <required/></p>
                        <el-input v-model="model.nombre" placeholder="Nombre" class="w-100" size="small" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
                <div class="col-6 my-2">
                    <ValidationProvider v-slot="{ errors }" name="materiales" rules="required">
                        <p class="input-label-top">Material que maneja  <required/></p>
                        <el-select v-model="model.materiales" multiple collapse-tags placeholder="Seleccionar material" size="small" class="w-100">
                            <el-option v-for="material in select_materials" :key="material.id" :label="material.nombre" :value="material.id" />
                        </el-select>
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
                <div class="col-6 my-2">
                    <ValidationProvider v-slot="{ errors }" name="ciudad" rules="required">
                        <p class="input-label-top">Ciudad principal  <required/></p>
                        <!-- <el-select v-model="model.ciudad" placeholder="Seleccionar ciudad" filterable size="small" class="w-100">
                            <el-option v-for="(ciudad, index) in ciudades" :key="index" :label="ciudad" :value="ciudad" />
                        </el-select> -->
                        <select-ciudades v-model="model.ciudad" class="w-100" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
                <div class="col-6 my-2">
                    <ValidationProvider v-slot="{ errors }" name="direccion" rules="required">
                        <p class="input-label-top">Dirección  <required/></p>
                        <el-input v-model="model.direccion" placeholder="dirección proveedor" class="w-100" size="small" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
                <div class="col-6 my-2">
                    <ValidationProvider v-slot="{ errors }" name="responsable" rules="required">
                        <p class="input-label-top">
                            Nombre del responsable 
                            <span class="f-13 text-danger">*</span> 
                        </p>
                        <el-select v-model="model.id_user_responsable" size="small" class="w-100">
                            <el-option v-for="(responsible, index) in select_responsibles" :key="index" :label="responsible.nombre" :value="responsible.id"/>
                        </el-select>
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
                <div class="col-6 my-2">
                    <ValidationProvider v-slot="{ errors }" name="numero_contacto" rules="required|numeric">
                        <p class="input-label-top">Número de contacto  <required/></p>
                        <el-input v-model="model.numero_contacto" placeholder="Número de contacto" class="w-100" size="small" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
                <div class="col-6 my-2">
                    <ValidationProvider v-slot="{ errors }" name="correo" rules="required|email">
                        <p class="input-label-top">Correo Electronico <required/></p>
                        <el-input v-model="model.correo" placeholder="Número de contacto" class="w-100" size="small" />
                        <vee-error :text="errors[0]" />
                    </ValidationProvider>
                </div>
                <div class="col-12 my-2">
                        <p class="input-label-top">Descripción </p>
                        <el-input v-model="model.descripcion" type="textarea" :autosize="{ minRows: detallesProveedor, maxRows: 4}" class="w-100" size="small" />
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
const model = {
    id: null,
    imagen: null,
    nombre: '',
    ciudad: null,
    direccion: '',
    id_user_responsable: null,
    numero_contacto: '',
    descripcion: '',
    materiales: null,
    telefono: '',
    correo: '',
}
export default {
    data(){
        return {
            slimOptions: {
                ratio: '1:1',
                label:'Añadir imagen proveedor',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            model: { ...model },
            image: null,
            ciudades: [
                'Bucaramanga',
                'Bogotá',
                'Cali',
                'Barranquilla'
            ],
        }
    },
    computed: {
        ...mapGetters({
            select_materials: 'selects/selects/select_materials',
            select_responsibles: 'selects/selects/select_responsible_users',
            detallesProveedor: 'proveedores/ver/ver/provider_details',
            key: 'files/files/key'
        }),
        id_proveedor(){
            return parseInt(this.$route.params.id_proveedor)
        },
        imagen(){
            return this.model?.imagen ?? null
        }
    },
    methods: {
        ...mapActions({
            removeBreadcumb: 'navigation/breadcumbs/removeBreadcumb',
            createProvider: 'proveedores/proveedores/Action_create_provider',
            Action_get_provider_details: 'proveedores/ver/ver/Action_get_provider_details',
            updateProvider: 'proveedores/proveedores/Action_edit_provider',
            Action_get_selects_city_paginated: 'selects/selects/Action_get_selects_city_paginated',
            uploadFile: 'files/files/Action_save_file_to_aws'
        }),
        toggle(){
            this.$refs.modalProvider.toggle()
            this.Action_get_selects_city_paginated()
        },
        edit(){
            const { 
                id,
                imagen, 
                nombre, 
                ciudad,
                direccion, 
                id_user_responsable, 
                descripcion, 
                materiales, 
                telefono, 
                correo 
            } = this.detallesProveedor
        
            this.model = {
                id,
                imagen,
                nombre,
                direccion,
                descripcion,
                telefono,
                correo,
                numero_contacto: telefono,
                id_user_responsable: id_user_responsable ?? null,
                materiales: materiales ?? null,
                ciudad: ciudad?.id ?? null,
            }

            this.$refs.modalProvider.toggle()
        },
        async handleSaveProvider(){
            const valid = await this.$refs.validator.validate()
            if(valid) {

                const base64Image = this.image

                if(base64Image) {
                    await this.uploadFile({
                        file: await this.base64ToFile(base64Image),
                        path: '/proveedores'
                    });
                }

                const payload = {
                    ...this.model,
                    telefono: this.model.numero_contacto,
                    imagen: base64Image ? this.key : this.detallesProveedor.imagen
                }

                if(this.model.id){
                    await this.updateProvider(payload)
                    this.$emit('getProvider')
                    this.removeBreadcumb(1)
                    await this.Action_get_provider_details({id_proveedor:this.$route.params.id_proveedor})

                }

                if(!this.model.id) await this.createProvider(payload)
                this.reset()
                this.$refs.modalProvider.toggle()

            }
        },
        reset(){
            this.model = {...model}
            this.$refs.validator.reset()
            this.$refs.cleanCropper.reset()
        },
    }
}
</script>

<style lang="css" scoped>
.cropper-proveedor{
    width: 123px; 
    height: 98px;
    background-color:#F5F5F5;
}
</style>
