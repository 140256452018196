var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modalProvider",attrs:{"tamano":"modal-lg","titulo":((_vm.id_proveedor ? 'Editar' : 'Crear') + " proveedor"),"adicional":_vm.id_proveedor ? 'Guardar' : 'Crear'},on:{"adicional":_vm.handleSaveProvider}},[_c('div',{staticClass:"ml-5"},[_c('required',{attrs:{"text":"Campos obligatorios"}})],1),_c('ValidationObserver',{ref:"validator"},[_c('div',{staticClass:"row justify-content-center m-3 f-15"},[_c('div',{staticClass:"col-12 mb-4"},[_c('p',{staticClass:"f-11 text-muted text-center"},[_vm._v("Imagen proveedor")]),_c('clean-cropper',{ref:"cleanCropper",staticClass:"cropper-proveedor border cr-pointer mx-auto br-5",attrs:{"options":_vm.slimOptions,"image":_vm.imagen},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}})],1),_c('div',{staticClass:"col-6 my-2"},[_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Nombre "),_c('required')],1),_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Nombre","size":"small"},model:{value:(_vm.model.nombre),callback:function ($$v) {_vm.$set(_vm.model, "nombre", $$v)},expression:"model.nombre"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 my-2"},[_c('ValidationProvider',{attrs:{"name":"materiales","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Material que maneja "),_c('required')],1),_c('el-select',{staticClass:"w-100",attrs:{"multiple":"","collapse-tags":"","placeholder":"Seleccionar material","size":"small"},model:{value:(_vm.model.materiales),callback:function ($$v) {_vm.$set(_vm.model, "materiales", $$v)},expression:"model.materiales"}},_vm._l((_vm.select_materials),function(material){return _c('el-option',{key:material.id,attrs:{"label":material.nombre,"value":material.id}})}),1),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 my-2"},[_c('ValidationProvider',{attrs:{"name":"ciudad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Ciudad principal "),_c('required')],1),_c('select-ciudades',{staticClass:"w-100",model:{value:(_vm.model.ciudad),callback:function ($$v) {_vm.$set(_vm.model, "ciudad", $$v)},expression:"model.ciudad"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 my-2"},[_c('ValidationProvider',{attrs:{"name":"direccion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Dirección "),_c('required')],1),_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"dirección proveedor","size":"small"},model:{value:(_vm.model.direccion),callback:function ($$v) {_vm.$set(_vm.model, "direccion", $$v)},expression:"model.direccion"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 my-2"},[_c('ValidationProvider',{attrs:{"name":"responsable","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v(" Nombre del responsable "),_c('span',{staticClass:"f-13 text-danger"},[_vm._v("*")])]),_c('el-select',{staticClass:"w-100",attrs:{"size":"small"},model:{value:(_vm.model.id_user_responsable),callback:function ($$v) {_vm.$set(_vm.model, "id_user_responsable", $$v)},expression:"model.id_user_responsable"}},_vm._l((_vm.select_responsibles),function(responsible,index){return _c('el-option',{key:index,attrs:{"label":responsible.nombre,"value":responsible.id}})}),1),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 my-2"},[_c('ValidationProvider',{attrs:{"name":"numero_contacto","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Número de contacto "),_c('required')],1),_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Número de contacto","size":"small"},model:{value:(_vm.model.numero_contacto),callback:function ($$v) {_vm.$set(_vm.model, "numero_contacto", $$v)},expression:"model.numero_contacto"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-6 my-2"},[_c('ValidationProvider',{attrs:{"name":"correo","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"input-label-top"},[_vm._v("Correo Electronico "),_c('required')],1),_c('el-input',{staticClass:"w-100",attrs:{"placeholder":"Número de contacto","size":"small"},model:{value:(_vm.model.correo),callback:function ($$v) {_vm.$set(_vm.model, "correo", $$v)},expression:"model.correo"}}),_c('vee-error',{attrs:{"text":errors[0]}})]}}])})],1),_c('div',{staticClass:"col-12 my-2"},[_c('p',{staticClass:"input-label-top"},[_vm._v("Descripción ")]),_c('el-input',{staticClass:"w-100",attrs:{"type":"textarea","autosize":{ minRows: _vm.detallesProveedor, maxRows: 4},"size":"small"},model:{value:(_vm.model.descripcion),callback:function ($$v) {_vm.$set(_vm.model, "descripcion", $$v)},expression:"model.descripcion"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }